/* You can add global styles to this file, and also import other style files */
html {
  font-size: 18px;
}
.verticalNavbar {
    margin-left:10px; 
    width: 220px;
    margin-right: 20px; 
    background-color: #f8f9fa; 
    border: 1px solid grey; 
    padding: 10px 10px 10px 10px;
}

.mainContainer {
    width: 100%;
    height: 100%;
}

/* ----------------------------- autoheight-container ----------------------------- */

/**
  autoheight-container permette di avere un contenuto flessibile in base al contenuto del div padre.
  I contenitori con classe "autoheight-fixed-item" saranno fissi e non verranno ridimensionati
  I contenitori con classe "autoheight-flexable-item" saranno flessibili e saranno ridimensionati dinamicamente
  Esempio:
    <div id="container" class="autoheight-container" style="width: 600px; height: 600px;">
      <div id="fixed1" class="autoheight-fixed-item">
        <div id="content1" style="height: 100px; width: 100%; background-color: red;"></div>
        <div id="content2" style="height: 100px; width: 100%; background-color: purple;"></div>
      </div>
      <div id="flexable" class="autoheight-flexable-item">
        <div id="content3" style="width: 100%; height: 200px; background-color: yellow;"></div>
        <div id="content4" style="width: 100%; height: 200px; background-color: green;"></div>
        <div id="content5" style="width: 100%; height: 200px; background-color: pink;"></div>
      </div>
      <div id="fixed2" class="autoheight-fixed-item">
        <div id="content6" style="height: 100px; width: 100%; background-color: blue;"></div>
      </div>
    </div>
  */

.autoheight-container {
  display: flex;
  flex-direction: column;
}

.autoheight-fixed-item {
  flex: auto;
}

.autoheight-flexable-item {
  flex: 100%;
  overflow: auto;
}

/* ----------------------------- Fill space container ----------------------------- */

/* ----------------------------- Stili bottoni predefiniti ----------------------------- */

/* Per distanziare l'icona a sinistra e un testo a destra (tipo nei pulsanti) */
.icon-btn{
  margin-right: 4px;
}

/* Centra orizzontalmente dei pulsanti es. conferma e annulla  */
.button-container-centered{
  display: flex;
  flex-direction: row;
  width: 100%;  
  justify-content: center;
  column-gap: 4em;
  margin-top: 20px;
}

/* Container con bordino */
.bordered-container {
  border: 1px solid #343a40;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
}

/* Container i dei pulsanti centrati, es. salva e annulla */
.button-container{
  display: flex;
  width: 100%;  
  justify-content: center;
  column-gap: 4rem;
  margin-top: 20px;
}